import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);

  const [firstName, setFirstName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div>
          <div
            style={{
              position: "relative",
              bottom: "440px",
              maxHeight: "40vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Container>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Card style={{ padding: "48px 24px", maxWidth: "800px" }}>
                  <CardHeader style={{ marginBottom: "12px" }}>
                    <div className="text-center">
                      <img
                        alt="..."
                        className="rounded-circle img-fluid img-raised"
                        src={require("assets/img/logo.png")}
                        style={{ width: "140px", height: "140px" }}
                      ></img>
                      <h2 style={{ marginTop: "24px", marginBottom: "6px" }}>
                        Suivez le <strong>Restaurant le Surya</strong>
                      </h2>
                      <h3 style={{ marginBottom: 0 }}>
                        et recevez des offres exclusives dans votre boite email
                      </h3>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center ml-auto mr-auto" md="8">
                        <InputGroup
                          className={
                            "input-lg" +
                            (firstFocus ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Prénom"
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            onFocus={() => setFirstFocus(true)}
                            onBlur={() => setFirstFocus(false)}
                          ></Input>
                        </InputGroup>
                        <InputGroup
                          className={
                            "input-lg" + (lastFocus ? " input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="text"
                            onFocus={() => setLastFocus(true)}
                            onBlur={() => setLastFocus(false)}
                          ></Input>
                        </InputGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={checked}
                              onChange={() => setChecked(!checked)}
                            />{" "}
                            J'accepte de recevoir des offres promotionnelles
                          </Label>
                        </FormGroup>
                        <div className="send-button">
                          <Button
                            block
                            className="btn-round"
                            color="info"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            style={{ backgroundColor: "#97853f" }}
                            size="lg"
                            disabled={!firstName || !email || !checked}
                          >
                            Participer
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Container>
          </div>
          <DefaultFooter />
        </div>
      </div>
    </>
  );
}

export default LandingPage;
